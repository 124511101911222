import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Row, Col, Container, Nav, CloseButton } from 'react-bootstrap';

import Layout from '@layouts/layout';
import SEO from '@components/seo';
import BannerContainer from '@components/bannerContainer';

const RefundPage = () => {
  return (
    <Layout
      headerProps={{
        disableLogin: true,
        NavigationOptions: (
          <Nav.Link onClick={() => navigate(-1)} className="ms-3">
            <CloseButton />
          </Nav.Link>
        ),
      }}
    >
      <SEO title="Política de reembolso y devoluciones" />

      <Container>
        <BannerContainer title="Política de reembolso y devoluciones" />

        <Row>
          <Col xs="12" md="10" lg="8" className="mx-auto py-4">
            <p>
              Si no estás completamente satisfecho con tu experiencia en
              Pizzería Habana, la corregiremos o te reembolsaremos tu dinero.
            </p>
            <p>
              Si el cliente califica para un reembolso, una vez que haya sido
              procesado del lado de Pizzería Habana, puede demorar hasta 30 días
              hábiles en aparecer reflejado en el registro de la tarjeta
              bancaria utilizada, dependiendo de la operación de las entidades
              financieras involucradas en la transacción.
            </p>
            <p>
              <b>
                Al utilizar nuestros servicios y aceptar nuestros Términos y
                Condiciones, usted acepta esta Política de Reembolso y
                Devoluciones.
              </b>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default RefundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
